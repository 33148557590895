export const menuItemsData = [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: 'About',
        url: '/about',
    },
    {
        title: 'Projects',
        url: '/projects',
    },
    {
        title: 'GitHub',
        url: 'https://github.com/awoolfson'
    },
];