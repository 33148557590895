export const SlidesData = [
    {
        path: "/carousel-images/headshot2.png",
        colorh1: "green",
        colorh2: "darkgreen"
    },
    {
        path: "/carousel-images/drums.PNG",
        colorh1: "darkblue",
        colorh2: "rgb(0, 15, 87)"
    },
    {
        path: "/carousel-images/hike.PNG",
        colorh1: "#6cc79e",
        colorh2: "#4d8d70"
    },
    {
        path: "/carousel-images/guitar.JPG",
        colorh1: "rgb(175, 58, 58)",
        colorh2: "rgb(113, 14, 14)"
    },
];